import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { IndividualItem } from './IndividualItem';
import StyledWrapper from './style';

import { clearAllFilters } from '../BaseLayout/slice';
import { logOut } from '../../pages/Login/slice';
import { useGetUserProfileQuery } from '../../services/endpoints/authenticationEndpoints';
import { setUser } from '../../pages/Login/slice';
import { getLocalAuth } from '../../utils/environmentUtils';

export function ProfileDropdown({ isOpen }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(isOpen || false);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const currentUser = useSelector((state) => state?.login?.user);
	const { data: userProfile, refetch: refetchForm } = useGetUserProfileQuery(apiToken);

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	useEffect(() => {
		userProfile &&
			dispatch(
				setUser({
					...userProfile,
					userPassword: null
				})
			);
	}, [userProfile]);

	return (
		<>
			<ClickAwayListener
				mouseEvent="onMouseDown"
				touchEvent="onTouchStart"
				onClickAway={handleClickAway}
			>
				<Box sx={{ position: 'relative' }}>
					<IconButton color="default" onClick={handleClick}>
						<img
							src="/images/user-placeholder.svg"
							alt="user-image"
							data-testid={'navbar-profile-icon'}
						/>
					</IconButton>
					{open ? (
						<StyledWrapper.Container>
							<StyledWrapper.Title data-testid={'profileDropdown-name'}>
								{`${currentUser?.userFirstName || currentUser?.firstName} ${
									currentUser?.userLastName || currentUser?.lastName
								}`}
							</StyledWrapper.Title>
							<StyledWrapper.Subtitle data-testid={'profileDropdown-role'}>
								{`${currentUser?.userTitle || currentUser?.title || ''}`}
							</StyledWrapper.Subtitle>
							<StyledWrapper.Divider />

							{/* <IndividualItem
								props={{
									link: '/settings',
									title: 'Settings',
									icon: <SettingsIcon color="action" />
								}}
							/>

							<IndividualItem
								props={{
									link: '/profile',
									title: 'Profile',
									icon: <AccountCircleIcon color="action" />
								}}
							/> */}

							<StyledWrapper.Divider />

							<StyledWrapper.LogOut
								onClick={() => {
									dispatch(clearAllFilters());
									dispatch(logOut());
									navigate('/');
								}}
							>
								Log out
							</StyledWrapper.LogOut>
						</StyledWrapper.Container>
					) : null}
				</Box>
			</ClickAwayListener>
		</>
	);
}
