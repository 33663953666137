import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getEntityPrefix } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import { setFormMetaInfo, setToReviewForms } from '../../components/FormBuilderLibrary/slice';
import { useGetIndividualProgramsQuery } from '../../services/endpoints/programsEndpoints';
import StyledWrapper from './style';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { endOfDay, formatTimestamp, startOfDay, addDays } from '../../utils/dateUtils';
import { APP_TYPE_APPLICATION_PROCESS } from '../ProgramSetup/subPages/StagesAndForms/constants';
import { ONLY_DATE } from '../../constants/common';

const APP_PROCESS_DURATION_DAYS = 90;

export const ProcessMetaInfo = ({
	readOnly: readOnlyProp,
	individualStage,
	isLoading,
	isAppProcess,
	setMetaInfoChanged,
	appProcessData
}) => {
	const dispatch = useDispatch();
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);

	const { data: individualProgram } = useGetIndividualProgramsQuery(
		{ id: programID, apiToken },
		{ refetchOnMountOrArgChange: true }
	);

	const formDates = isAppProcess
		? {
				startDate: new Date(),
				endDate: addDays(new Date(), APP_PROCESS_DURATION_DAYS)
		  }
		: {};

	const [expanded, setExpanded] = useState('panel1');
	const [readOnly, setReadOnly] = useState(readOnlyProp);
	const [dateChanged, setDateChanged] = useState(false);
	const [loadingState, setLoadingState] = useState(isLoading);
	const [initialData, setInitialData] = useState({});
	const [prevSubmissionStage, setPrevSubmissionStage] = useState();
	const [data, setData] = useState({
		title: '',
		id: '',
		programTitle: '',
		programID: '',
		instructions: '',
		bl: false,
		sequential: false,
		startDate: '',
		endDate: '',
		...formDates
	});

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const saveMetaInfo = () => {
		dispatch(setFormMetaInfo(data));
	};

	const handleDataChange = (inputName, value) => {
		setMetaInfoChanged && setMetaInfoChanged(true);

		// if (inputName === 'endDate') {
		// 	value = endOfDay(formatTimestamp(value));
		// } else if (inputName === 'startDate') {
		// 	value = startOfDay(formatTimestamp(value));
		// }

		setData({
			...data,
			[inputName]: value
		});
	};

	const hasFieldChanged = (fieldName) => {
		return initialData[fieldName] !== data[fieldName];
	};

	useEffect(() => {
		let previousSubmissionStage = null;
		let breakLoop = false;
		appProcessData?.forEach((e) => {
			if (e?.appId === individualStage?.stage_id) {
				breakLoop = true;
			}

			if (e?.type === 'Submission Stage' && !breakLoop) {
				previousSubmissionStage = e;
			}
		});
		setPrevSubmissionStage(previousSubmissionStage);
	}, [appProcessData, individualStage]);

	useEffect(() => {
		dateChanged && saveMetaInfo();
		dateChanged && setDateChanged(false);
	}, [dateChanged]);

	useEffect(() => {
		setData(formMetaInfo);
		setReadOnly(readOnlyProp);
	}, [formMetaInfo, readOnlyProp]);

	useEffect(() => {
		const Prefix = isAppProcess
			? getEntityPrefix({
					type: 'application-process',
					isMaters: true
			  })
			: getEntityPrefix({
					type:
						individualStage?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage',
					isMaters: true
			  });

		const stageInfo = {
			title: isAppProcess ? individualProgram?.title : individualStage?.stage_name,
			id: individualStage?.unique_identifier && `${Prefix}${individualStage?.unique_identifier}`,
			programTitle: individualProgram?.title,
			programID: individualStage?.program?.programId,
			instructions: individualStage?.instructions,
			startDate: individualStage?.start_date,
			endDate: individualStage?.end_date,
			type: individualStage?.stage_type,
			stage_forms: individualStage?.forms,
			sequential: true,
			// individualStage?.stage_order?.length > 0 || individualStage?.form_order?.length > 0,
			bl: individualStage?.stage_order?.length > 0 || individualStage?.form_order?.length > 0,
			order: individualStage?.stage_order || individualStage?.form_order || []
		};

		const payload = individualStage
			? stageInfo
			: {
					...individualProgram,
					...formDates,
					title: '',
					programTitle: individualProgram?.title,
					sequential: true
			  };

		dispatch(setFormMetaInfo(payload));
		dispatch(setToReviewForms(individualStage?.review_info || []));
		setInitialData(payload);
		setTimeout(function () {
			setLoadingState(false);
		}, 1000);
	}, [individualStage]);

	const finalTheme = createTheme({
		components: {
			MuiTextField: {
				variants: [
					{
						props: { disabled: false },
						style: {
							backgroundColor: 'white'
						}
					}
				]
			},
			MuiInputLabel: {
				defaultProps: {
					shrink: true
				}
			}
		}
	});

	return (
		<>
			{loadingState ? (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100px',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<StyledWrapper.MetaInfoContainer>
					<StyledWrapper.Accordion
						disableGutters
						elevation={0}
						square
						expanded={expanded === 'panel1'}
						onChange={handleChange('panel1')}
					>
						<StyledWrapper.AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="form-meta-header-content"
							id="form-meta-header"
						>
							APPLICATION PROCESS META SETTINGS & INFO
						</StyledWrapper.AccordionSummary>
						<StyledWrapper.AccordionDetails>
							<StyledWrapper.FormInfoOutermost>
								<StyledWrapper.FormInfoRow>
									<TextField
										disabled={readOnly || isAppProcess}
										required
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-title"
										label={isAppProcess ? 'Application Process Name' : 'Stage Name'}
										className={hasFieldChanged('title') ? 'isChanged' : ''}
										value={
											isAppProcess
												? `Application Process for Program: ${individualProgram?.title}`
												: data?.title
										}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('title', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
									<TextField
										disabled
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-id"
										label={isAppProcess ? 'Application Process ID' : 'Stage ID'}
										className={hasFieldChanged('id') ? 'isChanged' : ''}
										value={data?.id || '[]'}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('id', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
								</StyledWrapper.FormInfoRow>
								<StyledWrapper.FormInfoRow>
									<TextField
										disabled
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-programTitle"
										label="Program Name"
										value={individualProgram?.title}
										className={hasFieldChanged('programTitle') ? 'isChanged' : ''}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('programTitle', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
									<TextField
										disabled
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-programID"
										label="Program ID"
										value={individualProgram?.programId}
										className={hasFieldChanged('programID') ? 'isChanged' : ''}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('programID', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
								</StyledWrapper.FormInfoRow>
								<StyledWrapper.FormInfoRow>
									<ThemeProvider theme={finalTheme}>
										<TextField
											required
											disabled={readOnly}
											sx={{ width: '100%;' }}
											id="formBuilder-meta-input-instructions"
											label={
												isAppProcess ? 'Application Process Overall Instructions' : 'Instructions'
											}
											multiline
											minRows={2}
											value={data?.instructions || ''}
											className={hasFieldChanged('instructions') ? 'isChanged' : ''}
											color={'warning'}
											onChange={(event) => {
												handleDataChange('instructions', event.target.value);
											}}
											onBlur={saveMetaInfo}
										/>
									</ThemeProvider>
								</StyledWrapper.FormInfoRow>
								<StyledWrapper.FormInfoRow>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker
											required
											timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
											name={'startDate'}
											label={'Start Date'}
											value={data?.startDate || null}
											className={hasFieldChanged('startDate') ? 'isChanged' : ''}
											disabled={readOnly}
											minDate={!isAppProcess ? individualProgram?.start_date : null}
											maxDate={
												data?.endDate
													? data?.endDate
													: !isAppProcess
													? individualProgram?.end_date
													: null
											}
											color={'warning'}
											onChange={(newValue) => {
												handleDataChange('startDate', newValue, 'startDate');
												setDateChanged(!dateChanged);
											}}
											onBlur={saveMetaInfo}
											renderInput={(params) => (
												<TextField
													id={'datepicker-startDate'}
													minDate={!isAppProcess ? individualProgram?.start_date : null}
													maxDate={
														data?.endDate
															? data?.endDate
															: !isAppProcess
															? individualProgram?.end_date
															: null
													}
													color={'warning'}
													sx={{ background: 'white', borderRadius: '6px', width: '50%' }}
													name={'startDate'}
													data-testid={`datepicker-startDate`}
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: ONLY_DATE
													}}
												/>
											)}
										/>
										<DateTimePicker
											required
											name={'endDate'}
											label={'End Date'}
											value={data?.endDate || null}
											className={hasFieldChanged('endDate') ? 'isChanged' : ''}
											minDate={
												data?.startDate
													? data?.startDate
													: !isAppProcess
													? individualProgram?.start_date
													: null
											}
											maxDate={!isAppProcess ? formatTimestamp(individualProgram?.end_date) : null}
											disabled={readOnly}
											color={'warning'}
											onChange={(newValue) => {
												handleDataChange('endDate', newValue);
												setDateChanged(!dateChanged);
											}}
											onBlur={saveMetaInfo}
											renderInput={(params) => (
												<TextField
													id={'datepicker-endDate'}
													minDate={
														data?.startDate
															? data?.startDate
															: !isAppProcess
															? individualProgram?.start_date
															: null
													}
													maxDate={!isAppProcess ? individualProgram?.end_date : null}
													color={'warning'}
													sx={{ background: 'white', borderRadius: '6px', width: '50%' }}
													name={'endDate'}
													data-testid={`datepicker-endDate`}
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: ONLY_DATE
													}}
												/>
											)}
										/>
									</LocalizationProvider>
								</StyledWrapper.FormInfoRow>
								<button type="submit" className="meta-info-form" style={{ display: 'none' }} />
							</StyledWrapper.FormInfoOutermost>
							<br />
							{/* <StyledWrapper.FormRowTitle>
								BUSINESS LOGIC & RULES
								<SettingsSuggestIcon />
							</StyledWrapper.FormRowTitle>
							<StyledWrapper.FormInfoOutermost>
								<StyledWrapper.FormInfoRow justifyContent={'space-between'}>
									<FormControlLabel
										disabled={
											//	readOnly
											true
										}
										control={
											<Switch
												checked={data?.sequential}
												onChange={(newValue) =>
													// handleDataChange('sequential', newValue?.target?.checked)
													{
														dispatch(
															setFormMetaInfo({
																...data,
																sequential: newValue?.target?.checked
															})
														);
													}
												}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label={`Sequential Steps ${formMetaInfo?.sequential ? 'ON' : 'OFF'}`}
									/>
									<Button
										disabled={readOnly}
										sx={{ background: 'white', textTransform: 'capitalize' }}
										variant="outlined"
										startIcon={<SettingsSuggestIcon />}
									>
										Set Inter-Form Business Logic
									</Button>
								</StyledWrapper.FormInfoRow>
							</StyledWrapper.FormInfoOutermost> */}
						</StyledWrapper.AccordionDetails>
					</StyledWrapper.Accordion>
				</StyledWrapper.MetaInfoContainer>
			)}
		</>
	);
};
