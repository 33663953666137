import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { getLocalAuth } from '../../utils/environmentUtils';

export default function ArchiveDialog({ title, isOpen, type }) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(isOpen);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleClose = () => {
		setOpen(false);
		searchParams.delete('archiveModal');
		searchParams.delete('type');
		setSearchParams(searchParams);
	};

	const handleArchive = () => {
		let archiveFormOptions = {
			id: searchParams.get('archiveModal'),
			apiToken: apiToken
		};
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<div>
			<StyledWrapper.CustomDialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					<WarningIcon color={'warning'} />
					{`This Base ${type === 'application' ? 'Application' : 'Review'} Form is Shared Up!`}
				</StyledWrapper.CustomDialogTitle>
				<StyledWrapper.CustomDialogContent>
					<DialogContentText>
						{`You are trying to archive a Base ${
							type === 'application' ? 'Application' : 'Review'
						} Form that is shared up (used) in 4 ${
							type === 'application' ? 'Submission' : 'Review'
						} Stages. This will disconnect it from those stages entirely. There, it\'s instance will become Ad-Hock Templates.`}
					</DialogContentText>
					<DialogContentText sx={{ fontWeight: '500' }}>{`Are you sure?`}</DialogContentText>
				</StyledWrapper.CustomDialogContent>
				<DialogActions style={{ justifyContent: 'center' }}>
					<Button size="large" variant="outlined" color="warning" onClick={handleArchive} autoFocus>
						YES - DISCONNECT AND ARCHIVE
					</Button>
					<Button size="large" variant="contained" autoFocus onClick={handleClose}>
						NO - CLOSE OPTION
					</Button>
				</DialogActions>
			</StyledWrapper.CustomDialog>
		</div>
	);
}
