import { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { NavBar } from '../NavBar';
import { SideNav } from '../SideNav';
import { toggleSideNav } from '../SideNav/slice';
import {
	hideIFrameContent,
	getEunaOrangeColor,
	getEcivisBlueColor,
	getOldBlueColor,
	getViewInputColor
} from '../../utils/utilFunctions';
import { usePermissions } from '../../hooks/usePermissions';
import { BreadCrumb } from '../BreadCrumb';

const mdTheme = createTheme({
	palette: {
		primary: {
			main: `${getOldBlueColor()}`
		},
		secondary: {
			main: `${getEunaOrangeColor()}`
		}
	},
	components: {
		MuiTab: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: `${getEcivisBlueColor()}`
					}
				}
			}
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: '#F9FAFE'
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: '18px'
				}
			},
			defaultProps: {
				shrink: true
			}
		}
	}
});

const BaseLayout = () => {
	const [searchParams] = useSearchParams();
	const sideNavState = useSelector((state) => state.sideNav.value);
	const dispatch = useDispatch();
	const { userAccountTypeID } = usePermissions();

	// Open and Close SideNav
	const toggleDrawer = () => {
		dispatch(toggleSideNav());
	};

	useEffect(() => {
		searchParams?.get('iFrame') === 'true' && hideIFrameContent();
	}, [searchParams]);

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: 'flex', marginTop: '64px' }} data-testid="baseLayout-mainContent">
				<CssBaseline />

				{/* NAVBAR */}
				<NavBar
					open={sideNavState}
					toggleDrawer={toggleDrawer}
					userAccountTypeID={userAccountTypeID}
				/>

				{/* DRAWER/SIDENAV HERE */}
				{userAccountTypeID && searchParams?.get('iFrame') !== 'true' && (
					<SideNav open={sideNavState} toggleDrawer={toggleDrawer} />
				)}

				{/* MAIN CONTENT */}
				<Box
					id={'baselayout-box'}
					data-testid={'baselayout-box'}
					component="main"
					sx={{
						flexGrow: 1,
						height: 'calc(100vh - 74px)',
						overflow: 'auto'
					}}
				>
					{/* <Toolbar /> */}
					<Container
						data-testid={'baselayout-container'}
						sx={{
							maxWidth: '100%!important',
							paddingLeft: '37px!important',
							mt: 4,
							mb: 4
						}}
					>
						<BreadCrumb />
						<Outlet />
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default BaseLayout;
