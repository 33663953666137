import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonGroup from '@mui/material/ButtonGroup';

import StyledWrapper from './style';
import { ButtonDropdown } from '../core/ButtonDropdown/ButtonDropdown';
import ReassignIcon from '../../assets/customIcons/reassignIcon';
import TrashcanIcon from '../../assets/customIcons/trashcanIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewFormIcon';
import LinkReviewIcon from '../../assets/customIcons/linkReviewIcon';
import { EvalAssignmentsModal } from '../AssignReviewModal/EvalAssignmentsModal';
import ReassignReview from '../AssignReviewModal/ReassignReview';
import BulkReassignReview from '../AssignReviewModal/BulkReassignReview';
import { usePermissions } from '../../hooks/usePermissions';
import {
	useUpdateFormStatusMutation,
	useUpdateStageStatusMutation,
	useUpdateAppProcessStatusMutation,
	useReturnToApplicantMutation,
	useGetFormInstanceSchemaQuery
} from '../../services/endpoints/formBuilderEndpoints';
import SaveDialog from '../SaveDialog/SaveDialog';
import { disabledStatuses } from '../../constants/statuses';
import { areRequiredFieldsCompleted } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';

export const ActionButtons = ({ type, status, item, handleAlert, application_id }) => {
	const navigate = useNavigate();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const path = window.location.pathname;
	const pathSegments = path.split('/');

	const appID =
		application_id || pathSegments.find((segment) => segment.includes('api-'))?.replace('api-', '');
	const anchorRef = useRef(null);

	const applicationType = item?.appId?.split('-')?.[0];
	const uniqueID = item?.appId?.split('-')?.[1];
	const { data: apiFormData, isLoading } = useGetFormInstanceSchemaQuery(
		{
			uniqueID,
			apiToken: apiToken
		},
		{
			skip: !['afi', 'rfi'].includes(applicationType)
		}
	);
	const { form_schema: formSchema, field_values: formAnswers } = apiFormData || {};
	const completelyDisabled = disabledStatuses?.includes(item?.app_status);
	const [evalAssignmentModal, setEvalAssignmentModal] = useState(false);
	const [isDecisionsOpen, setDecisionsOpen] = useState(false);
	const [returnToApplicant] = useReturnToApplicantMutation();
	const [updateFormStatus] = useUpdateFormStatusMutation();
	const [updateStageStatus] = useUpdateStageStatusMutation();
	const [updateProcessStatus] = useUpdateAppProcessStatusMutation();
	const [saveModal, setSaveModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState('');
	const { hasEditPermissions, userAccountTypeID } = usePermissions();
	const [searchParams, setSearchParams] = useSearchParams();

	const updateStatusHandler = (status, alertID) => {
		// setSaveModal({ ...saveModal, isLoading: true });
		const options = {
			id: uniqueID,
			status: status,
			apiToken: apiToken
		};
		setButtonLoading(`${item?.appId}-${alertID}`);
		if (type === 'review content' || type === 'application form') {
			if (areRequiredFieldsCompleted(formSchema, formAnswers)) {
				updateFormStatus(options)
					.unwrap()
					.then(async () => {
						setButtonLoading(``);
						handleAlert('Status Updated Successfully!', 'success');
					})
					.catch(() => {
						setButtonLoading(``);
						handleAlert('Status Update Failed!', 'error');
					});
			} else {
				setButtonLoading(``);

				handleAlert('Status Update Failed!', 'error');
			}
		} else if (type === 'evaluation stage' && status === 'In Progress (paused)') {
			returnToApplicant({ ...options, id: appID })
				.unwrap()
				.then(async () => {
					setButtonLoading(``);
					handleAlert('Status Updated Successfully!', 'success');
				})
				.catch(() => {
					setButtonLoading(``);
					handleAlert('Status Update Failed!', 'error');
				});
		} else if (type === 'submission stage' || type === 'evaluation stage') {
			updateStageStatus(options)
				.unwrap()
				.then(async () => {
					setButtonLoading(``);
					handleAlert('Status Updated Successfully!', 'success');
				})
				.catch(() => {
					setButtonLoading(``);
					handleAlert('Status Update Failed!', 'error');
				});
		} else {
			updateProcessStatus({ ...options, id: appID })
				.unwrap()
				.then(async () => {
					setButtonLoading(``);
					handleAlert('Status Updated Successfully!', 'success');
				})
				.catch(() => {
					setButtonLoading(``);
					handleAlert('Status Update Failed!', 'error');
				});
		}
	};

	const FormButtons = () => {
		return (
			<>
				{(item?.isWithinDateRange ||
					status?.includes('reopened') ||
					status?.includes('returned')) && (
					<>
						{(status?.includes('not started') || status?.includes('draft')) &&
							(item?.all_stages_submitted || item?.type === 'Application Form') &&
							status !== 'completed' &&
							!completelyDisabled &&
							item?.is_current_stage &&
							userAccountTypeID !== 5 && (
								<Tooltip title="Edit Individual Form" placement="top">
									<LoadingButton
										size="small"
										variant="outlined"
										onClick={() =>
											navigate(
												`${item?.id_link}${
													searchParams.get('formtype') !== ''
														? `?formtype=${searchParams.get('formtype')}`
														: ''
												}`,
												{ state: { isEditing: true } }
											)
										}
									>
										Edit
									</LoadingButton>
								</Tooltip>
							)}

						{status?.includes('draft') &&
							!completelyDisabled &&
							item?.is_current_stage &&
							(item?.all_stages_submitted || item?.type === 'Application Form') &&
							userAccountTypeID !== 5 && (
								<Tooltip title="Change Form Status to Complete" placement="top">
									<LoadingButton
										size="small"
										loading={buttonLoading === `${item?.appId}-${'1'}`}
										variant="outlined"
										onClick={() => updateStatusHandler('Completed', '1')}
									>
										Complete
									</LoadingButton>
								</Tooltip>
							)}
					</>
				)}
				{status === 'completed' &&
					!completelyDisabled &&
					hasEditPermissions &&
					userAccountTypeID !== 5 && (
						<Tooltip title="Reopen Form" placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'2'}`}
								variant="outlined"
								onClick={() => updateStatusHandler('Draft (reopened)', '2')}
							>
								Reopen
							</LoadingButton>
						</Tooltip>
					)}
			</>
		);
	};

	const ReviewStageButtons = () => (
		<>
			{status !== 'submitted' &&
				!completelyDisabled &&
				item?.all_stages_submitted &&
				hasEditPermissions && (
					<>
						<Tooltip title="Assign Reviewers to Evaluation Stage." placement="top">
							<LoadingButton
								size="small"
								variant={
									item?.is_current_stage && status === 'unassigned' ? 'contained' : 'outlined'
								}
								onClick={() => setEvalAssignmentModal(true)}
							>
								Assignments
							</LoadingButton>
						</Tooltip>
					</>
				)}

			{item?.completion_number > 0 &&
				!completelyDisabled &&
				item?.all_stages_submitted &&
				hasEditPermissions && (
					<>
						<Tooltip title="Reopen All Completed Review Forms." placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'5'}`}
								variant="outlined"
								onClick={() => updateStatusHandler('In Progress (reopened)', '5')}
							>
								Reopen All
							</LoadingButton>
						</Tooltip>
					</>
				)}

			{/* TEMPORARILY HIDDEN UNTIL NEEDED IN PHASE 2 */}
			{/* {(status?.includes('in progress') ||
				status?.includes('completed') ||
				status?.includes('submitted')) &&
				status !== 'in progress (paused)' &&
				!completelyDisabled &&
				item?.all_stages_submitted &&
				hasEditPermissions && (
					<>
						<Tooltip title="Return to Applicant the Related Application Form(s)" placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'4'}`}
								variant="outlined"
								onClick={() => updateStatusHandler('In Progress (paused)', '4')}
							>
								Return All to Applicant
							</LoadingButton>
						</Tooltip>
					</>
				)} */}

			{status === 'completed' &&
				!completelyDisabled &&
				item?.is_current_stage &&
				item?.all_stages_submitted &&
				hasEditPermissions && (
					<>
						<Tooltip title="Submit the Entire Stage." placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'3'}`}
								variant="contained"
								onClick={() => updateStatusHandler('Submitted', '3')}
							>
								Submit
							</LoadingButton>
						</Tooltip>
					</>
				)}
		</>
	);

	const SubmissionStageButtons = () => (
		<>
			{item?.completion_number > 0 &&
				!status?.includes('reopened') &&
				!completelyDisabled &&
				hasEditPermissions && (
					<>
						<Tooltip title="Reopen All Completed Application Forms" placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'8'}`}
								variant="outlined"
								onClick={() => updateStatusHandler('In Progress (reopened)', '8')}
							>
								Reopen All
							</LoadingButton>
						</Tooltip>
					</>
				)}

			{status === 'completed' &&
				!completelyDisabled &&
				item?.is_current_stage &&
				!userAccountTypeID && (
					<>
						<Tooltip title="Submit the Entire Stage." placement="top">
							<LoadingButton
								size="small"
								loading={buttonLoading === `${item?.appId}-${'6'}`}
								variant="contained"
								onClick={() => updateStatusHandler('Submitted', '6')}
							>
								Submit
							</LoadingButton>
						</Tooltip>
					</>
				)}
		</>
	);

	const DropdownDecisions = {
		title: 'Decision',
		id: 'Decisions-1',
		marginBottom: '0px',
		variant: 'contained',
		options: [
			{
				title: 'Recommend',
				onClick: () => updateStatusHandler('Rec. Award', '12')
			},
			{
				title: 'On Hold',
				onClick: () => updateStatusHandler('On Hold', '12')
			},
			{
				title: 'Denied',
				onClick: () => updateStatusHandler('Denied', '12')
			}
		]
	};
	const AppInstanceButtons = () => (
		<>
			{status === 'completed' && hasEditPermissions && (
				<>
					<Tooltip title="Submit the Application for a Decision." placement="top">
						<LoadingButton
							size="small"
							loading={buttonLoading === `${item?.appId}-${'10'}`}
							variant="contained"
							onClick={() => updateStatusHandler('Pending Decision', '10')}
						>
							Submit for Decision
						</LoadingButton>
					</Tooltip>
				</>
			)}

			{status === 'pending decision' && hasEditPermissions && (
				<>
					<Tooltip title="Return the Application Process back to Completed State" placement="top">
						<LoadingButton
							size="small"
							loading={buttonLoading === `${item?.appId}-${'11'}`}
							variant="outlined"
							onClick={() => updateStatusHandler('Completed', '11')}
						>
							Revert
						</LoadingButton>
					</Tooltip>
					<ButtonDropdown
						data={DropdownDecisions}
						isOpen={isDecisionsOpen}
						customOpenToggle={setDecisionsOpen}
					/>
				</>
			)}

			{['on hold', 'denied'].includes(status) && hasEditPermissions && (
				<>
					<Tooltip
						title="Return the Application Process back to Pending Decision State"
						placement="top"
					>
						<LoadingButton
							size="small"
							loading={buttonLoading === `${item?.appId}-${'11'}`}
							variant="outlined"
							onClick={() => updateStatusHandler('Pending Decision', '11')}
						>
							Revert
						</LoadingButton>
					</Tooltip>
				</>
			)}

			{['on hold'].includes(status) && hasEditPermissions && (
				<Tooltip title="Recommend Application for Award." placement="top">
					<LoadingButton
						size="small"
						loading={buttonLoading === `${item?.appId}-${'12'}`}
						variant="contained"
						onClick={() => updateStatusHandler('Rec. Award', '12')}
					>
						Recommend
					</LoadingButton>
				</Tooltip>
			)}
		</>
	);

	return (
		<StyledWrapper.ButtonsContainer>
			{type === 'review content' || type === 'application form' ? (
				<FormButtons />
			) : type?.includes('evaluation stage') ? (
				<ReviewStageButtons />
			) : type?.includes('submission stage') ? (
				<SubmissionStageButtons />
			) : type?.includes('application instance') ? (
				<AppInstanceButtons />
			) : (
				<></>
			)}

			{evalAssignmentModal && hasEditPermissions && (
				<EvalAssignmentsModal
					item={item}
					openModal={evalAssignmentModal}
					closeModal={() => setEvalAssignmentModal(false)}
				/>
			)}

			{saveModal && (
				<SaveDialog
					isOpen={saveModal?.isOpen}
					isYesNo
					title={'Update Status'}
					loading={saveModal?.isLoading}
					isSuccess={saveModal?.isSuccess}
					isError={saveModal?.isError}
					handleClose={() => {
						setSaveModal(false);
					}}
					handleSave={() => updateStatusHandler(saveModal?.status)}
				/>
			)}
		</StyledWrapper.ButtonsContainer>
	);
};

export const AssignmentsButton = ({
	item,
	isBulk = false,
	disabled = false,
	stageID,
	handleAlert
}) => {
	const [isReassigning, setIsReassigning] = useState();

	const handleOpenReassignModal = (choice) => {
		setIsReassigning(choice);
	};

	const addStageButton = {
		title: isBulk ? 'Bulk Manage Assignments' : 'Manage Assignments',
		id: 198567,
		tipPlacement: 'left',
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: '',
		width: 'fit-content',
		options: [
			{
				title: 'Reassign & Keep Content',
				endIcon: (
					<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
						<ReassignIcon />
						<ReviewFormIcon />
					</div>
				),
				disabled: disabled,
				onClick: () => handleOpenReassignModal('reassign & keep')
			},
			{
				title: 'Reassign & Delete Content',
				endIcon: (
					<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
						<ReassignIcon />
						<TrashcanIcon />
					</div>
				),
				disabled: disabled,
				onClick: () => handleOpenReassignModal('reassign & delete')
			},
			{
				title: 'Unassign & Delete Content',
				endIcon: (
					<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
						<LinkReviewIcon />
						<TrashcanIcon />
					</div>
				),
				disabled: disabled,
				onClick: () => handleOpenReassignModal('unassign & delete')
			}
		]
	};

	return (
		<>
			<ButtonDropdown data={addStageButton} />
			{isReassigning && !isBulk && (
				<ReassignReview
					item={item}
					openModal={isReassigning}
					type={isReassigning}
					closeModal={() => setIsReassigning(false)}
					stageID={stageID}
				/>
			)}
			{isReassigning && isBulk && (
				<BulkReassignReview
					item={item}
					openModal={isReassigning}
					type={isReassigning}
					closeModal={() => setIsReassigning(false)}
					stageID={stageID}
					handleAlert={handleAlert}
				/>
			)}
		</>
	);
};
