import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import StyledWrapper from '../IndividualProgram/style';

import { checkOverflow } from '../../utils/utilFunctions';
import { getChipColor, getChipTextColor } from '../../utils/utilFunctions';
import ProgressBar from '../../components/Graphs/ProgressBar/ProgressBar';
import { formatTimestamp } from '../../utils/dateUtils';
import { TableTitle } from '../../components/TableTitle/TableTitle';
const ReviewColumns = (openTooltip, setOpenTooltip) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const columnWidths = useMemo(() => {
		return {};
	}, []);

	const columns = [
		{
			field: 'project_title',
			headerName: 'Project Title',
			description: 'Project Title',
			renderCell: (cellValues) => {
				const id = cellValues.row?.unique_identifier;
				const formattedID = id ? `api-${id}` : '--';
				const currentStage = cellValues.row?.form_stage_id;
				const programId = cellValues.row?.program?.programId;
				const titleData = {
					displayId: formattedID,
					title: cellValues.row.project_title,
					status: cellValues.row.status,
					entityType: 'Application Instance'
				};
				const handleCardClick = () => {
					//ARM-116. Need to keep iframe as a query param
					navigate(
						`/programs/p-${programId}/applications/${formattedID}?formtype=review${
							searchParams?.get('iFrame') === 'true' ? '&iFrame=true' : ''
						}`
					);
				};
				return (
					<>
						<TableTitle handleCardClick={handleCardClick} titleData={titleData} />
					</>
				);
			},
			flex: !columnWidths.appId && 1,
			minWidth: 250
		},
		{
			field: 'status',
			headerName: 'App Status',
			description: 'App Status',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `status-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={cellValues?.formattedValue}
								sx={{
									backgroundColor: getChipColor(cellValues?.formattedValue),
									color: getChipTextColor(cellValues?.formattedValue)
								}}
								size="small"
							/>
						</Tooltip>
					</>
				);
			},
			flex: !columnWidths.status && 1
		},
		{
			field: 'org_name',
			headerName: 'Organization (Submitter)',
			description: 'Organization (Submitter)',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues?.formattedValue || '--';
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.title && 1
		},
		{
			field: 'total_forms',
			headerName: 'Nr of Forms',
			description: 'Nr of Forms',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues.formattedValue?.toString() || '0';
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.completion && 1
		},
		{
			field: 'stage',
			headerName: 'Present Stage',
			description: 'Present Stage',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage ? currentStage?.name : '--';
				return (
					currentStage?.stage_type !== 'application' && (
						<BasicCellText
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
						/>
					)
				);
			},
			flex: !columnWidths.stage && 1
		},
		{
			field: 'stage_status',
			headerName: 'Stage Status',
			description: 'Stage Status',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage?.status;
				return (
					<>
						{currentStage?.stage_type !== 'application' && currentStage && (
							<Tooltip
								open={openTooltip === `status-${cellValues?.id}`}
								onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
								onClose={() => setOpenTooltip(0)}
								arrow
								placement="top"
								title={cellValues?.formattedValue}
							>
								<Chip
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
									label={cellValues?.formattedValue}
									sx={{
										backgroundColor: getChipColor(cellValues?.formattedValue),
										color: getChipTextColor(cellValues?.formattedValue)
									}}
									size="small"
								/>
							</Tooltip>
						)}
					</>
				);
			},
			flex: !columnWidths.status && 1,
			headerAlign: 'right',
			align: 'right'
		},
		{
			field: 'created_on',
			type: 'date',
			headerName: 'Started',
			description: 'Started',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage?.start_date;
				return currentStage?.stage_type !== 'application' && currentStage ? (
					<BasicCellText
						isDate
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				) : (
					''
				);
			},
			flex: !columnWidths.startDate && 1
		},
		{
			field: 'last_updated_on',
			type: 'date',
			headerName: 'Last Edited',
			description: 'Last Edited',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;

				return currentStage?.stage_type !== 'application' && currentStage ? (
					<BasicCellText
						isDate
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				) : (
					''
				);
			},
			flex: !columnWidths.endDate && 1
		},
		{
			field: 'remainingTime',
			headerName: 'Remaining Time',
			renderCell: (cellValues) => {
				const row = cellValues?.row;
				const currentStage = row?.form_stage_id;
				const start_date = currentStage?.start_date || row?.process_start_date;
				const end_date = currentStage?.end_date || row?.process_end_date;
				const startDate = start_date?.split('T')?.[0] + 'T00:00:00';
				const endDate = end_date?.split('T')?.[0] + 'T00:00:00';
				let remainingTimeData = {
					startDate: new Date(startDate),
					endDate: new Date(endDate),
					isCountdown: true
				};
				return (
					currentStage?.stage_type !== 'application' &&
					currentStage && <ProgressBar testTitle={'manageApplication'} data={remainingTimeData} />
				);
			},
			flex: !columnWidths.remainingTime && 1
		}
	];

	return columns;
};

export default ReviewColumns;

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : ''}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
