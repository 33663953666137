import styled from 'styled-components';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getOldBlueColor } from '../../utils/utilFunctions';

// FORM INFO
const Accordion = styled(MuiAccordion)`
	background: #cfd8dc !important;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
	font-weight: 700;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	max-width: fit-content;
`;

const AccordionDetails = styled(MuiAccordionDetails)``;

const FormInfoOutermost = styled.form`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-left: 15px;

	&::before {
		content: ' ';
		position: absolute;
		left: 0px;
		height: 100%;
		width: 3px;
		background: #90a4ae;
	}
`;

const FormInfoRow = styled.div`
	width: 100%;
	display: flex;
	gap: 10px;

	& .MuiInputBase-root.Mui-disabled {
		border: 1px dashed rgba(0, 0, 0, 0.23);
		-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
	}

	& .MuiInputBase-root.Mui-disabled div {
		-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
	}

	& .isChanged .MuiFormLabel-root {
	}

	& .isChanged .MuiOutlinedInput-notchedOutline {
		border-width: 2px !important;
	}

	& input {
		border-radius: 6px;
		background: white;
	}

	& .Mui-focused {
		& fieldset {
			border-color: ${getOldBlueColor()} !important;
		}
	}
	& label {
		&.Mui-focused {
			color: rgba(0, 0, 0, 0.6);
		}
	}
`;
export default {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormInfoRow,
	FormInfoOutermost
};
