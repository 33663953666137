import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SectionCard } from '../../../components/SectionCard';
import PieChart from '../../../components/Graphs/PieChart/PieChart';
import { pieChartData } from '../../../components/Graphs/PieChart/mockdata';
import ProgressBar from '../../../components/Graphs/ProgressBar/ProgressBar';
import { ApplicationsData } from '../../../components/Graphs/ProgressBar/mockdata';
import { HelpIcon } from '../../../components/core/HelpIcon';
import { useGetApplicationsPieChartQuery } from '../../../services/endpoints/programsEndpoints';

import Tooltip from '@mui/material/Tooltip';

import StyledWrapper from '../style';
import { getChipColor } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';

const Applications = () => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	let { programID, programTitle } = useParams();
	programID = programID?.replace('p-', '');

	const [pieChartData, setPieChartData] = useState([]);
	const { data } = useGetApplicationsPieChartQuery(
		{ apiToken, programID },
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		if (data) {
			let tempArr = [];
			Object.entries(data?.statuses || {}).forEach(([key, value]) => {
				tempArr.push({
					id: key,
					value: value,
					color: getChipColor(key)
				});
			});
			setPieChartData(tempArr);
		}
	}, [data]);

	return (
		<SectionCard minWidth={'49%'} width={'100%'} noscroll={true}>
			<StyledWrapper.CardTitleContainer data-testid={`overview-applications-title-container`}>
				<Tooltip title={`View all Program Applications`} placement="top" arrow>
					<StyledWrapper.CardTitle
						data-testid={`overview-applications-title`}
						to={`/programs/p-${programID}/applications`}
					>
						Applications ({data?.total_applications || 0})
					</StyledWrapper.CardTitle>
				</Tooltip>
			</StyledWrapper.CardTitleContainer>
			{data?.total_applications > 0 ? (
				<>
					<StyledWrapper.CardRowContainer width={'92%'}>
						<StyledWrapper.CardRowTitle
							data-testid={`overview-applications-row-label`}
							margin={'15px 0px 0px 0px'}
						>
							Time Remaining <HelpIcon data-testid={`overview-applications-row-icon`} />
						</StyledWrapper.CardRowTitle>
						<StyledWrapper.CardRowInfo>
							<ProgressBar
								testTitle={'overviewApplications'}
								data={{
									startDate: new Date(data?.min_start_date),
									endDate: new Date(data?.max_end_date),
									isCountdown: true
								}}
							/>
						</StyledWrapper.CardRowInfo>
					</StyledWrapper.CardRowContainer>

					<StyledWrapper.ChartContainer data-testid={'nivo-pieChart'}>
						{pieChartData?.length > 0 && (
							<PieChart testTitle={'overview-applications'} data={pieChartData} />
						)}
					</StyledWrapper.ChartContainer>
				</>
			) : (
				<StyledWrapper.CardRowInfo width={'100%'} maxWidth={'100%'} align={'center'}>
					{'No Applications'}
				</StyledWrapper.CardRowInfo>
			)}
		</SectionCard>
	);
};

export default Applications;
