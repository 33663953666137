export const getTimeRemaining = (endDateProp, startDateProp = new Date().valueOf()) => {
	let endDate = new Date(endDateProp).valueOf();
	let nowDate = startDateProp;

	let diffTime = Math.abs(endDate - nowDate);
	let days = diffTime / (24 * 60 * 60 * 1000);
	let hours = (days % 1) * 24;
	let minutes = (hours % 1) * 60;
	let secs = (minutes % 1) * 60;
	[days, hours, minutes, secs] = [
		Math.floor(days),
		Math.floor(hours),
		Math.floor(minutes),
		Math.floor(secs)
	];

	if (startDateProp >= endDateProp) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			timeRemaining: '0days 0h 0m'
		};
	} else {
		return {
			days: days,
			hours: hours,
			minutes: minutes,
			timeRemaining: `${days ? days + 'd' : '0days'} ${hours ? hours + 'h' : '0h'} ${
				minutes ? minutes + 'm' : '0m'
			}`
		};
	}
};

export const twoDatesPercentage = (endDateProp, startDateProp = new Date().valueOf()) => {
	let startDate = startDateProp?.getTime();
	let endDate = endDateProp?.getTime();
	let nowDate = new Date().getTime();
	let percentage = (nowDate - startDate) / (endDate - startDate);
	return percentage * 100;
};

export const formatDate = (dateString, separator = '.') => {
	const formattedDate = dateString && new Date(dateString).toISOString().slice(0, 10);
	return formattedDate ? formattedDate?.replaceAll('-', separator) : '--';
};

// export function formatTimestamp(utcTimestamp) {
// 	const date = new Date(utcTimestamp);
// 	const month = date.getUTCMonth() + 1;
// 	const day = date.getUTCDate();
// 	const year = date.getUTCFullYear();

// 	return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
// }

export function formatTimestamp(utcTimestamp) {
	if (!utcTimestamp) {
		return '-';
	}

	// Create a Date object from the UTC timestamp
	const utcDate = new Date(utcTimestamp);
	// fixed
	// in the backend 2024-03-12T03:44:24.869643Z (filter data by 03/12/2024)
	// was in the table 03/11/2024 -> try to filter data using 03/11/2024
	utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());
	const formattedDate = utcDate.toLocaleDateString('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric'
	});

	return formattedDate;
}

//06/16/2023 04:11PM
export const formatDateWithTime = (dateString) => {
	const date = new Date(dateString);

	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const year = String(date.getFullYear());
	const hours = String(date.getHours() % 12 || 12).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const ampm = date.getHours() < 12 ? 'AM' : 'PM';

	return `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
};

// Fixes validation failure when date is typed instead of selected one in DatePicker, as it uses dayjs dates instead of native Date object
const getDateFromDateJS = (date) => {
	if (date?.hasOwnProperty('$isDayjsObject')) {
		return date.toDate();
	}
	return date;
};

export function checkAllDateRanges(objects, initialStartDate, initialEndDate) {
	let isDatesInRange = true;
	const initialStart = getDateFromDateJS(initialStartDate);
	const initialEnd = getDateFromDateJS(initialEndDate);

	if (objects?.length > 0) {
		for (const obj of objects) {
			const { start_date, end_date } = obj;

			if (!start_date && !end_date) continue;

			const startDate = getDateFromDateJS(start_date);
			const endDate = getDateFromDateJS(end_date);

			if (startDate && new Date(startDate) < new Date(initialStart)) {
				isDatesInRange = false;
				break;
			}

			if (endDate && new Date(endDate) > new Date(initialEnd)) {
				isDatesInRange = false;
				break;
			}
		}
	}
	return isDatesInRange;
}

export const toISOString = (date) => {
	const finalDate = Date.parse(date) ? date : parseInt(date); //convert timestamp to int
	return new Date(finalDate);
};

export const endOfDay = (yourDate) => {
	const dateObject = new Date(yourDate).setHours(23, 59, 0, 0);
	return new Date(dateObject);
};

export const startOfDay = (yourDate) => {
	const dateObject = new Date(yourDate).setHours(0, 0, 1, 999);
	return new Date(dateObject);
};

export const isBefore = (date, dateToCompare) => {
	if (!date || !dateToCompare) {
		return false;
	}

	const firstDate = new Date(date);
	const secondDate = new Date(dateToCompare);
	return firstDate.getTime() < secondDate.getTime();
};

export const addDays = (date, days) => {
	const dateCopy = new Date(date);
	dateCopy.setDate(dateCopy.getDate() + days);
	return dateCopy;
};
