// Application Statuses
export const COMPLETED = 'completed';
export const DENIED = 'denied';
export const ON_HOLD = 'on hold';
export const PENDING_DECISION = 'pending decision';
export const REC_AWARD = 'rec. award';
export const FULL_REVIEW = 'full review';
export const CLOSED = 'closed';

// Input Placeholders
export const ONLY_DATE = 'mm/dd/yyyy';
