import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import StyledWrapper from '../style';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AppForm from '../components/AppForm/AppForm';
import ReviewForm from '../components/ReviewForm/ReviewForm';
import { Instructions } from '../../../components/FormBuilder/customComponents/Instructions';
import { setPencilIcon, areRequiredFieldsCompleted } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';
import { ButtonDropdown } from '../../../components/core/ButtonDropdown/ButtonDropdown';
import ReviewFormIcon from '../../../assets/customIcons/reviewForm';
import { useGetUniqueFormQuery } from '../../../services/endpoints/formBuilderEndpoints';
import { useGetFormInstanceSchemaQuery } from '../../../services/endpoints/formBuilderEndpoints';
import { useUpdateFormStatusMutation } from '../../../services/endpoints/formBuilderEndpoints';
import {
	clearFormSchema,
	setToReviewForms,
	setFormSchema,
	setFormData,
	clearValues
} from '../../../components/FormBuilderLibrary/slice';
import { disabledStatuses } from '../../../constants/statuses';
import { usePermissions } from '../../../hooks/usePermissions';
import { checkInRange, useIndividualApplicationData } from '../../IndividualApplication/utils';

const ReviewDetails = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [updateFormStatus] = useUpdateFormStatusMutation();

	const [alertBalloon, setAlertBalloon] = useState(false);
	const [readOnly, setReadOnly] = useState(location?.state?.isEditing ? false : true);
	const [loadingCancel, setLoadingCancel] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [isWithinRange, setIsWithinRange] = useState(false);
	const { hasEditPermissions, userAccountTypeID } = usePermissions();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const pathArray = location?.pathname?.split('/');
	const masterForm = pathArray?.filter((e) => e?.includes('rfm-'))?.[0];
	const reviewForm = pathArray?.filter((e) => e?.includes('rfi-'))?.[0];
	let subPage = reviewForm || masterForm || '';

	const { data: application } = useIndividualApplicationData();

	const uniqueID =
		subPage && subPage?.replace('rfm-', '')?.replace('-group', '')?.replace('rfi-', '');

	const uniqueForm = useGetUniqueFormQuery(
		{
			uniqueID,
			apiToken
		},
		{ skip: !props?.isMasterLayout }
	);

	const formInstanceSchema = useGetFormInstanceSchemaQuery(
		{
			uniqueID,
			apiToken
		},
		{ skip: props?.isMasterLayout }
	);

	const { data, isLoading, refetch } = props?.isMasterLayout ? uniqueForm : formInstanceSchema;

	useEffect(() => {
		setLoadingCancel(true);
		dispatch(clearFormSchema());
		dispatch(setToReviewForms([]));
		dispatch(clearValues());
		refetch();

		setReadOnly(location?.state?.isEditing ? false : true);
	}, [location.pathname]);

	const instructions = props?.isMasterLayout ? data?.instructions : data?.form?.instructions;

	useEffect(() => {
		const isReturned = props?.navData?.[0]?.status?.includes('returned');
		const isReopened = data?.status?.includes('reopened');
		const overrideDates = isReturned || isReopened;
		if (data && props?.isMasterLayout) {
			const isWithinDateRange = checkInRange(application, data?.form?.stage, overrideDates);
			setIsWithinRange(isWithinDateRange);
			dispatch(setFormSchema([0, [...(data?.form_schema ?? [])]]));
			dispatch(setToReviewForms(data?.stage?.review_info));
		} else if (data) {
			const isWithinDateRange = checkInRange(application, data?.form?.stage, overrideDates);
			setIsWithinRange(isWithinDateRange);

			dispatch(setFormSchema([0, [...(data?.form_schema ?? [])]]));
			dispatch(setFormData([1, data?.field_values] || []));
			dispatch(setToReviewForms(data?.form?.stage?.review_info));
		}
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	}, [application, data, dispatch, isLoading, props?.isMasterLayout, props?.navData]);

	useEffect(() => {
		setPencilIcon(readOnly);
	}, [readOnly]);

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 3000);
	};

	return (
		<>
			<StyledWrapper.OutermostContainer>
				<Instructions data={{ content: instructions }} />
				<StyledWrapper.FormHeader>
					{/* <StyledWrapper.LastEdited data-testid={'individualreview-lastedited'}>
						Review Last edited by: Antonio Banderas, 03/12/2022 4:22AM{' '}
					</StyledWrapper.LastEdited> */}
					<Collapse in={alertBalloon}>
						<Alert
							severity={alertBalloon?.status}
							action={
								<IconButton
									aria-label="close"
									color={alertBalloon?.status}
									size="small"
									onClick={() => {
										setAlertBalloon(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2, mt: 2 }}
						>
							{alertBalloon?.message}
						</Alert>
					</Collapse>
					{(hasEditPermissions || !userAccountTypeID) && (
						<StyledWrapper.LastEditedActions>
							{readOnly ? (
								<>
									{props.isFormsContainer && <BluePrintDropdown />}

									{!props.isFormsContainer && isWithinRange && (
										<>
											<Button
												disabled={data.status === 'Completed'}
												data-testid={'individualreview-edit-button'}
												variant="outlined"
												onClick={() => setReadOnly(false)}
												startIcon={<EditIcon />}
											>
												Edit Review
											</Button>
											{data.status.toLowerCase().includes('draft') && (
												<LoadingButton
													loading={buttonLoading === 'complete'}
													variant="contained"
													component="label"
													data-testid={'individualapp-save-button'}
													onClick={() => {
														if (areRequiredFieldsCompleted(data.form_schema, data.field_values)) {
															setButtonLoading('complete');
															let id = props?.formID?.split('-')?.[1];
															const options = {
																id: id,
																status: 'Completed',
																apiToken: apiToken
															};
															updateFormStatus(options)
																.unwrap()
																.then(async () => {
																	setButtonLoading(false);
																	handleAlert('Status Updated Successfully!', 'success');
																})
																.catch(() => {
																	setButtonLoading(false);
																	handleAlert('Status Update Failed!', 'error');
																});
														} else {
															setButtonLoading(``);
															handleAlert(
																'Status Update Failed! Required fields are not filled in!',
																'error'
															);
														}
													}}
												>
													Complete
												</LoadingButton>
											)}
											{data.status.toLowerCase().includes('completed') &&
												!disabledStatuses?.includes(data?.status) && (
													<LoadingButton
														loading={buttonLoading === 'reopen'}
														variant="contained"
														component="label"
														data-testid={'individualapp-save-button'}
														onClick={() => {
															setButtonLoading('reopen');
															let id = props?.formID?.split('-')?.[1];
															const options = {
																id: id,
																status: 'Draft (returned)',
																apiToken: apiToken
															};
															updateFormStatus(options)
																.unwrap()
																.then(async () => {
																	setButtonLoading(false);
																	handleAlert('Status Updated Successfully!', 'success');
																})
																.catch(() => {
																	setButtonLoading(false);
																	handleAlert('Status Update Failed!', 'error');
																});
														}}
													>
														Reopen
													</LoadingButton>
												)}
										</>
									)}
								</>
							) : (
								isWithinRange && (
									<>
										<Button
											variant="contained"
											component="label"
											data-testid={'individualreview-save-button'}
											onClick={() => {
												const element = document.getElementById(`${props?.formID}-saveButton`);
												element.click();
											}}
										>
											Save
										</Button>
										<Button
											variant="outlined"
											onClick={() => setReadOnly(true)}
											data-testid={'individualreview-cancel-button'}
										>
											Cancel
										</Button>
									</>
								)
							)}
						</StyledWrapper.LastEditedActions>
					)}
				</StyledWrapper.FormHeader>
				{loadingCancel || isLoading ? (
					<>
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<CircularProgress />
						</Box>
					</>
				) : (
					<StyledWrapper.InnerContainer>
						<AppForm
							isMasterLayout={props?.isMasterLayout}
							appId={props?.appId}
							appFormTitle={'Application Forms to Review'}
						/>
						<ReviewForm
							apiFormData={data}
							isMasterLayout={props?.isMasterLayout}
							reviewer={props?.reviewer}
							readOnly={props?.isMasterLayout || readOnly}
							setReadOnly={setReadOnly}
							formID={props?.formID}
							disableActionButtons
							staticInstructions={instructions}
						/>
					</StyledWrapper.InnerContainer>
				)}
			</StyledWrapper.OutermostContainer>
		</>
	);
};

export default ReviewDetails;

export const BluePrintDropdown = () => {
	const navigate = useNavigate();
	let { programID } = useParams();

	const addStageButton = {
		title: 'Assignments',
		id: 198567,
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		options: [
			{
				disabled: false,
				title: 'To Master Setup',
				icon: <ReviewFormIcon isBlueprint />,
				onClick: () => {
					navigate(`/programs/${programID}/application-setup/application`);
				}
			}
		]
	};

	return <ButtonDropdown isHorizon data={addStageButton} />;
};
