import { is_within_range } from '../../utils/utilFunctions';
import { useSelector } from 'react-redux';
import { getLocalAuth } from '../../utils/environmentUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { GPM } from '../../services/gpmAPI';

interface Application {
	[key: string]: unknown;
	startDate: Date | null;
	endDate: Date | null;
	start_date: Date | null;
	end_date: Date | null;
}

type Stage = Application;

export const checkInRange = (
	application: Application,
	stage: Stage,
	override: boolean
): boolean => {
	const stageOrApplicationDates = {
		start_date: stage?.start_date || stage?.startDate || application?.process_start_date,
		end_date: stage?.end_date || stage?.endDate || application?.process_end_date
	};
	return is_within_range(stageOrApplicationDates, override);
};

const gpm = GPM as typeof GPM & {
	endpoints: {
		// @ts-ignore: ignoring to use the endpoints from js file (without types)
		getIndividualApplication: typeof GPM.endpoints.getIndividualApplication;
	};
};

export const useIndividualApplicationData = () => {
	const accountSource = useSelector(
		(state: { login: { accountSource: string } }) => state?.login?.accountSource
	);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const { appID } = useParams();
	const [searchParams] = useSearchParams();
	const formType = searchParams.get('formtype') || '';
	const iFrame = searchParams.get('iFrame') || '';
	const uniqueID = appID?.split('-')?.[1];

	const queryParams = useMemo(
		() => ({
			uniqueID,
			apiToken,
			formType,
			iFrame
		}),
		[uniqueID, apiToken, formType, iFrame]
	);

	return gpm.endpoints.getIndividualApplication.useQueryState(queryParams);
};
