import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ApplicationColumns from './applicationColumns';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { Table } from '../../components/Table';
import StyledWrapper from '../IndividualProgram/style';
import { useGetUserInvitesQuery } from '../../services/endpoints/projectTeamEndpoints';
import {
	useAcceptInvitationMutation,
	useRejectInvitationMutation
} from '../../services/endpoints/projectTeamEndpoints';
import { useGetUserApplicationsQuery } from '../../services/endpoints/programsEndpoints';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import { getLocalAuth } from '../../utils/environmentUtils';

export const UserApplications = ({ invitesOnly }) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	// useStates
	const [openTooltip, setOpenTooltip] = useState(0);
	const initialColumns = ApplicationColumns(openTooltip, setOpenTooltip);
	const [tableColumns, setTableColumns] = useState(initialColumns);
	const [rowGroupingModel] = useState(['program']);
	const [buttonLoading, setButtonLoading] = useState();
	const [apiStatusAlert, setApiStatusAlert] = useState();
	const [data, setData] = useState();

	const pagination =
		!invitesOnly &&
		usePaginatedCall(useGetUserApplicationsQuery, { filterBaseName: 'USER_APPLICATIONS' });
	const [acceptInvite] = useAcceptInvitationMutation();
	const [rejectInvite] = useRejectInvitationMutation();

	const { data: apiData } = useGetUserInvitesQuery(
		{
			apiToken
		},
		{ refetchOnMountOrArgChange: true }
	);

	const handleAlert = (message, status) => {
		setApiStatusAlert({
			message,
			status
		});
		setTimeout(function () {
			setApiStatusAlert();
		}, 2000);
	};

	const removeInvitation = (invitationID) => {
		const formattedData = data?.filter((e) => e?.team_invitation_id !== invitationID);
		setData(formattedData);
	};

	const theme = createTheme({
		palette: {
			secondary: {
				main: '#9C27B0'
			}
		}
	});

	useEffect(() => {
		apiData && setData(apiData);
	}, [apiData]);

	useEffect(() => {
		setOpenTooltip(0);
	}, [rowGroupingModel]);

	useEffect(() => {
		setTableColumns(initialColumns);
	}, [rowGroupingModel, openTooltip]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Stack sx={{ width: '100%' }} spacing={2}>
					{apiStatusAlert && (
						<Alert variant="outlined" severity={apiStatusAlert?.status}>
							{apiStatusAlert?.message}
						</Alert>
					)}
					{data?.map((invite, index) => {
						if (['ACCEPTED', 'REJECTED'].includes(invite?.status)) return;

						const inviter = invite?.inviter_id;
						const inviterText =
							inviter?.firstName && inviter?.lastName
								? `${inviter?.firstName} ${inviter?.lastName}`
								: inviter?.userEmail || inviter?.userID;
						const application = invite?.application;
						const applicationText =
							application?.project_title || `application api-${application?.unique_identifier}`;

						return (
							<Alert
								variant="outlined"
								key={`user_invite_${index}`}
								severity="info"
								action={
									<div
										style={{
											display: 'flex',
											gap: '10px',
											marginLeft: 'auto'
										}}
									>
										<LoadingButton
											loading={buttonLoading === `user_invite_${index}-accept`}
											variant="contained"
											onClick={async () => {
												setButtonLoading(`user_invite_${index}-accept`);
												await acceptInvite({
													invitationID: invite?.team_invitation_id,
													apiToken: apiToken
												})
													.unwrap()
													.then(async () => {
														removeInvitation(invite?.team_invitation_id);
														handleAlert('Invitation Accepted Successfully!', 'success');
													})
													.catch(() => handleAlert('Invitation Accepting Failed!', 'error'));
												setButtonLoading();
											}}
										>
											ACCEPT
										</LoadingButton>
										<LoadingButton
											loading={buttonLoading === `user_invite_${index}-reject`}
											variant="outlined"
											onClick={async () => {
												setButtonLoading(`user_invite_${index}-reject`);
												await rejectInvite({
													invitationID: invite?.team_invitation_id,
													apiToken: apiToken
												})
													.unwrap()
													.then(async () => {
														removeInvitation(invite?.team_invitation_id);
														handleAlert('Invitation Rejected Successfully!', 'success');
													})
													.catch(() => handleAlert('Invitation Rejection Failed!', 'error'));
												setButtonLoading();
											}}
										>
											REJECT
										</LoadingButton>
									</div>
								}
							>
								{`You are invited by "${inviterText} to join the "${applicationText}"`}
							</Alert>
						);
					})}
					<br />
				</Stack>

				{!invitesOnly && (
					<StyledWrapper.TableContainer>
						<h4
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								paddingBottom: '30px'
							}}
						>
							Applications
						</h4>
						<Table
							getRowId={(cell) => cell?.application_id}
							columns={tableColumns}
							rowHeight={52}
							enableAutoPageSize
							rows={pagination?.rows?.results || []}
							rowCount={pagination?.rowCount}
							pagination={pagination?.pagination}
							pageSize={pagination?.pageSize}
							paginationMode={pagination?.paginationMode}
							onPageChange={(e) => pagination?.onPageChange(e)}
							onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
							page={pagination?.page}
							loading={pagination?.loading}
							sortingMode={pagination?.sortingMode}
							onSortModelChange={(e) => pagination?.onSortModelChange(e)}
							onFilterModelChange={(e) => console.log(e)}
							filterModel={pagination?.filterModel}
							sortModel={pagination?.sortModel}
						/>
					</StyledWrapper.TableContainer>
				)}
			</ThemeProvider>
		</>
	);
};
