import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { FormBuilder } from '../FormBuilder/FormBuilder';
import { TabPanel, a11yProps } from '../TabPanel';
import { setFormSchema, setFormHasChanged } from '../FormBuilderLibrary/slice';
import { useGetIndividualFormSchemaQuery } from '../../services/endpoints/formBuilderEndpoints';
import { compareMultiLevelArray } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

export default function BasicTabs() {
	const dispatch = useDispatch();
	const reduxSchema = useSelector((state) => state.form.formSchema);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [searchParams] = useSearchParams();
	const [value, setValue] = useState(0);
	const [tabs, setTabs] = useState([0]);

	const [refresh, setRefresh] = useState(false);
	const [schemaState, setSchemaState] = useState();
	const [showAddButton, setShowAddButton] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const apiOptions = {
		formId: searchParams.get('form') || '',
		apiToken: apiToken
	};
	const { data: apiFormData, isLoading: formsApiLoading } =
		useGetIndividualFormSchemaQuery(apiOptions);

	useEffect(() => {
		if (apiFormData) {
			let data = !reduxSchema ? [null] : reduxSchema;
			data = apiFormData?.[0] !== undefined && !data[0] ? apiFormData : data;
			dispatch(setFormSchema([-1, data]));
			setSchemaState(data);
			setTabs(data?.map((e, index) => index));
		}
	}, [apiFormData, formsApiLoading]);

	useEffect(() => {
		let isSame = compareMultiLevelArray(apiFormData, reduxSchema);
		dispatch(setFormHasChanged(!isSame));
	}, [schemaState, reduxSchema, apiFormData]);

	useEffect(() => {
		let formattedSchema = reduxSchema.filter((e) => e !== null);
		let formattedTabs = tabs.filter((e) => e !== null);
		formattedSchema.length === formattedTabs.length ? addButtonState() : setShowAddButton(false);
		setIsLoading(false);
	}, [reduxSchema, tabs]);

	const addButtonState = () => {
		let formattedSchema = reduxSchema.filter((e) => e !== null);
		let showButton = false;
		if (formattedSchema && formattedSchema[formattedSchema?.length - 1]?.length > 0) {
			showButton = true;
		}
		setShowAddButton(showButton);
	};

	return (
		<Box sx={{ width: '100%', position: 'relative' }}>
			<IconButton
				data-testid={'multistepform-add-button'}
				onClick={() => {
					let newTabs = tabs.filter((e) => e !== null);
					newTabs.push(newTabs.length);
					setTabs(newTabs);
					setValue(newTabs.length - 1);
					setRefresh(!refresh);
				}}
				aria-label="fingerprint"
				color="secondary"
				style={{
					display: showAddButton ? 'flex' : 'none',
					position: 'absolute',
					right: '30px',
					color: '#009DAC',
					zIndex: '100'
				}}
			>
				<AddIcon />
			</IconButton>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					data-testid={'multistepform-tabs'}
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{tabs?.map((e, index) => {
						return <Tab key={`FormTab-${index}`} label={`Step ${e + 1}`} {...a11yProps(e + 1)} />;
					})}
				</Tabs>
			</Box>

			{!isLoading && schemaState ? (
				tabs?.map((e, index) => {
					return (
						<TabPanel key={`TabPanel-${index}`} value={value} index={e} style={{ height: '100vh' }}>
							<FormBuilder
								data={schemaState[`${e}`]}
								saveData={(schema) => {
									setSchemaState(schema);
								}}
								formId={e}
							/>
						</TabPanel>
					);
				})
			) : (
				<Box sx={{ display: 'flex', margin: '50px 0px', justifyContent: 'center' }}>
					<CircularProgress data-testid={'multistepform-loading'} />
				</Box>
			)}
		</Box>
	);
}
