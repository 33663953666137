import { useSelector, useDispatch } from 'react-redux';
import { usePostLoginMutation } from '../services/endpoints/authenticationEndpoints';
import { getCookie } from '../utils/utilFunctions';
import { getLocalAuth, ARM_TOKEN_COOKIE, ACCOUNT_SOURCE } from '../utils/environmentUtils';
import { setAccountSource } from '../pages/Login/slice';

const useAuthentication = () => {
	//ARM-241: Using gpm-auth-gn and gpm-auth-portal depending on the account source the token is from. This is so we can have seperate permissions when a user is using arm from gn and in portal
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [callLogin] = usePostLoginMutation();
	const dispatch = useDispatch();

	const hasAccess = async () => {
		try {
			const token = getCookie(ARM_TOKEN_COOKIE);
			const payload = { jwt: token };
			if (!apiToken) {
				if (!Boolean(token)) {
					return false;
				}
				const response = await callLogin(payload);
				if (response?.data) {
					// Check if we are coming from gn or portal
					if (response.data?.accountSource === ACCOUNT_SOURCE.GRANTS_NETWORK) {
						dispatch(setAccountSource({ accountSource: ACCOUNT_SOURCE.GRANTS_NETWORK }));
						localStorage.setItem('gpm-auth-gn', JSON.stringify(response.data));
						// Assume portal if not gn, or if no account source
					} else {
						dispatch(setAccountSource({ accountSource: ACCOUNT_SOURCE.PORTAL }));
						localStorage.setItem('gpm-auth-portal', JSON.stringify(response.data));
					}
					return response?.data?.access_token ? true : false;
				}
			} else {
				return true;
			}
		} catch (error) {
			console.error(error);
		}
	};

	return {
		hasAccess: () => hasAccess()
	};
};

export default useAuthentication;
