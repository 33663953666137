import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from '../style';

import InfoCard from './InfoCard';
import Applications from './Applications';
import Reviews from './Reviews';

import { useGetIndividualProgramsQuery } from '../../../services/endpoints/programsEndpoints';
import { getLocalAuth } from '../../../utils/environmentUtils';

const Overview = () => {
	const navigate = useNavigate();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	// API CALL
	const { data, isFetching } = useGetIndividualProgramsQuery({ apiToken, id: programID });

	return (
		<StyledWrapper.OverviewContainer data-testid={'overview-container'}>
			<StyledWrapper.OverviewRow data-testid={'overview-row'}>
				<InfoCard width={'100%'} data={data} isFetching={isFetching} />
				{/* <NotificationCard width={'50%'} data={rows} title={"Program's Notifications"} /> */}
			</StyledWrapper.OverviewRow>
			<StyledWrapper.OverviewRow data-testid={'overview-row-2'}>
				<Applications />
				<Reviews />
			</StyledWrapper.OverviewRow>
		</StyledWrapper.OverviewContainer>
	);
};

export default Overview;
