import { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from '../style';
import { formatDetailAnswers, generateFormSchema } from './utils';
import { getLocalAuth } from '../../../../utils/environmentUtils';

import { MultiFormViewer } from '../../../../components/MultiFormViewer';
import { useGetIndividualProgramsQuery } from '../../../../services/endpoints/programsEndpoints';

// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import EditIcon from '@mui/icons-material/Edit';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ProgramDetails = () => {
	const location = useLocation();
	const FormContainer = useRef(null);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	const pathArray = location?.pathname?.split('/');
	const subPage = pathArray[pathArray.length - 1];

	// const [readOnly, setReadOnly] = useState(true);
	const [formAnswers, setFormAnswers] = useState([]);
	const [finalFormSchema, setFinalFormSchema] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const {
		data: individualProgram,
		isLoading: isLoadingProgram,
		error
	} = useGetIndividualProgramsQuery({
		id: programID,
		apiToken
	});

	useEffect(() => {
		// Check if individualProgram is available before processing
		if (individualProgram != undefined) {
			const finalFormSchema = generateFormSchema(individualProgram);
			setFinalFormSchema(finalFormSchema);

			const finalFormAnswers = formatDetailAnswers(individualProgram);
			setFormAnswers(finalFormAnswers);
		}
	}, [individualProgram]);

	useEffect(() => {
		// Render the page only when data is available and not loading
		if (!isLoadingProgram && formAnswers.length > 0 && finalFormSchema.length > 0) {
			setIsLoading(false);
		}
	}, [isLoadingProgram, formAnswers, finalFormSchema]);
	return (
		<>
			{subPage !== 'Overview' && !isLoading ? (
				error?.status ? (
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						No Data Available
					</Box>
				) : (
					<StyledWrapper.FormContainer ref={FormContainer}>
						<StyledWrapper.FormHeader>
							{/* <StyledWrapper.LastEditedActions>
							{readOnly ? (
								<>
									{' '}
									<IconButton
										aria-label="moreOptions"
										data-testid={'programDetails-moreOptions-icon'}
									>
										<MoreHorizIcon />
									</IconButton>
									<Button
										data-testid={'programDetails-editbutton'}
										variant="outlined"
										onClick={() => setReadOnly(false)}
										startIcon={<EditIcon />}
									>
										Edit
									</Button>
								</>
							) : (
								<>
									<Button
										variant="contained"
										component="label"
										data-testid={'programDetails-savebutton'}
									>
										Save
									</Button>
									<Button
										variant="outlined"
										onClick={() => setReadOnly(true)}
										data-testid={'programDetails-cancelbutton'}
									>
										Cancel
									</Button>
								</>
							)}
						</StyledWrapper.LastEditedActions> */}
						</StyledWrapper.FormHeader>

						<MultiFormViewer
							formAnswers={[[], formAnswers]}
							form={[finalFormSchema]}
							readOnly={true}
							disableFooterButtons
							disableActionButtons
							hideActionButtons
							funded={true}
						/>
					</StyledWrapper.FormContainer>
				)
			) : (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '50vh',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};

export default ProgramDetails;
