import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';

const OutermostContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 30px;
`;

const InnerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	align-items: flex-start;
	padding: ${(props) => props?.padding};
	margin: ${(props) => props?.margin};
`;

const DetailTitleContainer = styled.div`
	position: relative;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	background: #e8ecee;
	margin-bottom: 32px;
	padding: 0px 10px;
`;

const DetailTitle = styled.p`
	display: flex;
	gap: 15px;
	font-weight: 500;
	width: fit-content;
	margin: ${(props) => props?.margin || '19px auto'};
`;

const ActionButtonContainer = styled.div`
	display: flex;
	height: fit-content;
	gap: 10px;

	@media (max-width: 1600px) {
		position: relative;
	}
`;

const CustomIconButton = styled(IconButton)`
	background-color: rgba(255, 255, 255, 0.3) !important;
`;

const CustomAccordion = styled(Accordion)`
	box-shadow: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);

	&::before,
	&::after {
		display: none;
	}
`;

const CardFormContainer = styled.div`
	width: 100%;
	padding: 10px;

	& .react-form-builder-form .rfb-item label {
		color: ${(props) => props?.readOnly && 'black'};
		font-weight: ${(props) => props?.readOnly && '500'};
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormHeader = styled.div`
	display: flex;
	margin: 0px 0px 20px 0px;
	justify-content: space-between;
	align-items: center;
`;

const LastEdited = styled.h6`
	margin: 0px;
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 5px;
	margin-left: auto;
`;

const BackIconContainer = styled.div`
	width: 100%;
	position: absolute;
	top: -16px;
`;

//TOTAL SCORE SECTION
const ScoreOuterContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const ScoreContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const Score = styled.p`
	font-size: 24px;
	margin-bottom: 0px;
	color: ${(props) => (props?.score >= 90 ? '#388E3C' : props?.score >= 75 ? '#FF9800' : 'black')};
`;

const ScoreOverall = styled.h6`
	color: rgba(0, 0, 0, 0.6);
	margin-bottom: 0px;
`;

export default {
	OutermostContainer,
	InnerContainer,
	DetailTitleContainer,
	DetailTitle,
	ActionButtonContainer,
	CustomIconButton,
	CustomAccordion,
	CardFormContainer,
	FormContainer,
	FormHeader,
	LastEdited,
	LastEditedActions,
	BackIconContainer,
	ScoreOuterContainer,
	ScoreContainer,
	Score,
	ScoreOverall
};
