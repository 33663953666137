import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { SectionCard } from '../../../../components/SectionCard';
import ResizePanelContainer from '../../../../components/ResizePanelContainer/ResizePanelContainer';
import { useApplicationSetup } from '../../../../hooks/useApplicationSetup';
import { IndividualFormSelection } from '../../../StageCreationModal/components/StageAppSelection/IndividualFormSelection';
import StyledWrapper from '../../style';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { useGetIndividualAppTable } from '../../../../hooks/useGetIndividualAppTable';
import { useGetFormsBeingReviewedQuery } from '../../../../services/endpoints/formBuilderEndpoints';
import { getLocalAuth } from '../../../../utils/environmentUtils';

const AppForm = ({ appFormTitle, isMasterLayout }) => {
	const [searchParams] = useSearchParams();
	const [expanded, setExpanded] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [allExpanded, setAllExpanded] = useState(false);
	const [allForms, setAllForms] = useState([]);
	const [stageId, setStageId] = useState('');
	const formId = searchParams?.get('formId');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const path = window.location.pathname;
	const pathSegments = path.split('/');
	const appID = pathSegments.find((segment) => segment.includes('api-'));

	const toReview = useSelector((state) => state?.form?.to_review);
	// const { data: allStagesAndForms } = useGetIndividualAppTable({ appId: appID });

	const list_of_fields = toReview?.map((e) => e?.review_inputs);
	const { data: FormsBeingReviewed, error } = useGetFormsBeingReviewedQuery({
		apiToken,
		payload: {
			application_id: appID?.split('-')[1],
			ids: toReview?.map((e) => e?.formId?.split('-')[1]),
			fields: list_of_fields ? [].concat(...list_of_fields) : []
		}
	});

	const handleExpansion = (expandedAccordion) => {
		setAllExpanded(false);

		const expandedFormatted = expanded;
		const existsIndex = expandedFormatted?.indexOf(expandedAccordion);
		existsIndex !== -1
			? expandedFormatted.splice(existsIndex, 1)
			: expandedFormatted.push(expandedAccordion);

		setExpanded(expandedAccordion === '' ? [] : expandedFormatted);
		setRefresh(!refresh);
	};

	// useEffect(() => {
	// 	const onlyAppForms = allStagesAndForms?.filter((e) => e?.type === 'Application Form');

	// 	if (Array.isArray(toReview)) {
	// 		const filteredForms = onlyAppForms?.filter((e) => {
	// 			const formIsReviewing = toReview?.findIndex((x) => x?.formId === e?.master_id);
	// 			if (formIsReviewing >= 0) {
	// 				return e;
	// 			}
	// 		});

	// 		onlyAppForms && setAllForms(filteredForms);
	// 	}
	// }, [allStagesAndForms, toReview]);

	// useEffect(() => {
	// 	if (allStagesAndForms) {
	// 		const chosenItem = allStagesAndForms?.find((e) => e?.appId === formId);
	// 		setStageId(chosenItem?.stageID);
	// 	}
	// }, [allStagesAndForms]);

	return (
		<ResizePanelContainer panelStyle={{ width: '400px' }} innerProps={{ right: '-10px' }}>
			<SectionCard width={'100%'} height={'fit-content'} noscroll={true} padding={'0px'}>
				<StyledWrapper.DetailTitleContainer data-testid={`appForm-title-container`}>
					<StyledWrapper.DetailTitle>
						{stageId && `Reviewed Applications`}
						{appFormTitle}
					</StyledWrapper.DetailTitle>
					<StyledWrapper.ActionButtonContainer>
						<StyledWrapper.CustomIconButton
							onClick={() => setAllExpanded(true)}
							aria-label="unfold"
						>
							<UnfoldMoreIcon />
						</StyledWrapper.CustomIconButton>
						<StyledWrapper.CustomIconButton onClick={() => handleExpansion('')} aria-label="fold">
							<UnfoldLessIcon />
						</StyledWrapper.CustomIconButton>
					</StyledWrapper.ActionButtonContainer>
				</StyledWrapper.DetailTitleContainer>

				{FormsBeingReviewed?.map((form, index) => (
					<IndividualFormSelection
						key={`individualform-selection-${index}`}
						readOnly={true}
						allExpanded={allExpanded}
						appId={form?.form?.unique_identifier}
						title={form?.form?.label}
						expanded={expanded}
						isReviewing={true}
						isMasterLayout={isMasterLayout}
						handleExpansion={handleExpansion}
						prefetchedValues={form?.field_values || []}
						prefetchedSchema={form?.form_schema || []}
					/>
				))}
			</SectionCard>
		</ResizePanelContainer>
	);
};

export default AppForm;
