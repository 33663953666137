import { useSelector } from 'react-redux';

import { useBulkActionTemplateMutation } from '../services/endpoints/templatesEndpoints';
import { getLocalAuth } from '../utils/environmentUtils';

const useBulkTemplateActions = (type) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	const [bulkAction] = useBulkActionTemplateMutation();

	const entityID = () => {
		let id = '';
		switch (type) {
			case 'application-process':
				id = 'process_id';
				break;
			case 'stage':
				id = 'stage_id';
				break;
			case 'form':
				id = 'form_id';
				break;
		}
		return id;
	};

	const handleBulkAction = (selectedItems, handleAlert, action) => {
		const id = entityID();

		let nonDeletable = [];

		selectedItems?.map((e) => {
			if (e?.linked_count > 0) {
				nonDeletable?.push(e?.table_id);
			}
			//ARM-235 Can delete archived templates
		});

		if (nonDeletable?.length > 0 && action === 'delete') {
			handleAlert(`${nonDeletable?.map((e, index) => ` ${e}`)} cannot be deleted!`, 'error');
			return;
		}

		let deleteFormOptions = {
			payload: {
				entities: selectedItems?.map((e) => {
					return e?.[id];
				}),
				type: type,
				action: action
			},
			apiToken: apiToken
		};

		bulkAction(deleteFormOptions)
			.unwrap()
			.then(async () => {
				handleAlert('Bulk Action Successfull!', 'success');
			})
			.catch(() => {
				handleAlert('Bulk Action Successfull!', 'error');
			});
	};

	return {
		handleBulkAction
	};
};

export default useBulkTemplateActions;
