import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import StyledWrapper from '../IndividualProgram/style';

import { checkOverflow } from '../../utils/utilFunctions';
import { getChipColor, getChipTextColor } from '../../utils/utilFunctions';
import ProgressBar from '../../components/Graphs/ProgressBar/ProgressBar';
import { formatTimestamp } from '../../utils/dateUtils';
import AppProcessIcon from '../../assets/customIcons/applicantProcess';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';

const ApplicationColumns = (openTooltip, setOpenTooltip) => {
	const navigate = useNavigate();
	const columnWidths = useMemo(() => {
		return {};
	}, []);

	const columns = [
		{
			field: 'appId',
			headerName: 'Application ID',
			description: 'Application ID',
			renderCell: (cellValues) => {
				const id = cellValues?.row?.unique_identifier;
				const formattedID = id ? `api-${id}` : '--';
				const currentStage = cellValues?.row?.form_stage_id;
				const programID = cellValues?.row?.program?.programId;
				return (
					<>
						<Tooltip
							open={openTooltip === `appId-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`appId-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfoButton
								variant="outlined"
								disabled={!id ? true : false}
								onClick={() =>
									navigate(
										`/programs/p-${programID}/applications/api-${cellValues?.row?.unique_identifier}`
									)
								}
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								width={'80%'}
							>
								<AppProcessIcon
									isCurrentStage={!currentStage || currentStage === ''}
									isActive={id ? true : false}
								/>
								<p>{formattedID}</p>
							</StyledWrapper.CardRowInfoButton>
						</Tooltip>
					</>
				);
			},
			flex: !columnWidths.appId && 1
		},
		{
			field: 'status',
			headerName: 'App Status',
			description: 'App Status',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `status-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={cellValues?.formattedValue}
								sx={{
									backgroundColor: getChipColor(cellValues?.formattedValue),
									color: getChipTextColor(cellValues?.formattedValue)
								}}
								size="small"
							/>
						</Tooltip>
					</>
				);
			},
			flex: !columnWidths.status && 1,
			headerAlign: 'right',
			align: 'right'
		},
		{
			field: 'project_title',
			headerName: 'Project Title',
			description: 'Project Title',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues?.formattedValue || '--';
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.title && 1
		},

		{
			field: 'total_forms',
			headerName: 'Nr of Forms',
			description: 'Nr of Forms',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues.formattedValue?.toString() || '0';
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.completion && 1
		},

		{
			field: 'stage',
			headerName: 'Present Name',
			description: 'Present Name',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage ? currentStage?.name : '--';
				return (
					currentStage?.stage_type !== 'evaluation' && (
						<BasicCellText
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
						/>
					)
				);
			},
			flex: !columnWidths.stage && 1
		},
		{
			field: 'stageID',
			headerName: 'Stage ID',
			description: 'Stage ID',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage ? currentStage?.unique_identifier : '--';
				const programID = cellValues?.row?.program?.programId;
				return (
					currentStage?.stage_type !== 'evaluation' &&
					currentStage?.unique_identifier && (
						<>
							<Tooltip
								open={openTooltip === `stageID-${cellValues?.id}`}
								onOpen={(e) => checkOverflow(e) && setOpenTooltip(`stageID-${cellValues?.id}`)}
								onClose={() => setOpenTooltip(0)}
								arrow
								placement="top"
								title={cellValues?.formattedValue}
							>
								<StyledWrapper.CardRowInfoButton
									variant="outlined"
									onClick={() =>
										navigate(
											`/programs/p-${programID}/applications/api-${cellValues?.row?.unique_identifier}/ssi-${cellValues?.formattedValue}`
										)
									}
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
									width={'80%'}
								>
									<SubmissionStageIcon isCurrentStage />
									<p>{cellValues?.formattedValue ? `ssi-${cellValues?.formattedValue}` : ''}</p>
								</StyledWrapper.CardRowInfoButton>
							</Tooltip>
						</>
					)
				);
			},
			flex: !columnWidths.stageID && 1
		},
		{
			field: 'stage_status',
			headerName: 'Stage Status',
			description: 'Stage Status',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = currentStage?.status;
				return (
					<>
						{currentStage?.stage_type !== 'evaluation' && currentStage?.status && (
							<Tooltip
								open={openTooltip === `status-${cellValues?.id}`}
								onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
								onClose={() => setOpenTooltip(0)}
								arrow
								placement="top"
								title={cellValues?.formattedValue}
							>
								<Chip
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
									label={cellValues?.formattedValue}
									sx={{
										backgroundColor: getChipColor(cellValues?.formattedValue),
										color: getChipTextColor(cellValues?.formattedValue)
									}}
									size="small"
								/>
							</Tooltip>
						)}
					</>
				);
			},
			flex: !columnWidths.status && 1,
			headerAlign: 'right',
			align: 'right'
		},
		{
			field: 'created_on',
			type: 'date',
			headerName: 'Started',
			description: 'Started',
			renderCell: (cellValues) => {
				const row = cellValues?.row;
				const currentStage = row?.form_stage_id;
				cellValues.formattedValue = currentStage?.start_date || row?.process_start_date;
				return currentStage?.stage_type !== 'evaluation' && cellValues?.row?.form_stage_id ? (
					<BasicCellText
						isDate
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				) : (
					''
				);
			},
			flex: !columnWidths.startDate && 1
		},
		{
			field: 'last_updated_on',
			type: 'date',
			headerName: 'Last Edited',
			description: 'Last Edited',
			renderCell: (cellValues) => {
				const currentStage = cellValues?.row?.form_stage_id;
				cellValues.formattedValue = cellValues?.row?.last_updated_on;
				return currentStage?.stage_type !== 'evaluation' && cellValues?.row?.form_stage_id ? (
					<BasicCellText
						isDate
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				) : (
					''
				);
			},
			flex: !columnWidths.endDate && 1
		},
		{
			field: 'remainingTime',
			headerName: 'Remaining Time',
			renderCell: (cellValues) => {
				const row = cellValues?.row;
				const currentStage = row?.form_stage_id;

				const start_date = currentStage?.start_date || row?.process_start_date;
				const end_date = currentStage?.end_date || row?.process_end_date;

				const startDate = start_date?.split('T')?.[0] + 'T00:00:00';
				const endDate = end_date?.split('T')?.[0] + 'T00:00:00';

				let remainingTimeData = {
					startDate: new Date(startDate),
					endDate: new Date(endDate),
					isCountdown: true
				};
				return (
					currentStage?.stage_type !== 'evaluation' &&
					cellValues?.row?.form_stage_id && (
						<ProgressBar testTitle={'manageApplication'} data={remainingTimeData} />
					)
				);
			},
			flex: !columnWidths.remainingTime && 1
		}
	];

	return columns;
};

export default ApplicationColumns;

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : ''}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
