import { toISOString } from '../../utils/dateUtils';

interface FormMetaInfo {
	[key: string]: unknown;
	startDate: string | number | Date;
	endDate: string | number | Date;
}

export const stageDateValidation = (formMetaInfo: FormMetaInfo): boolean => {
	if (formMetaInfo?.startDate == null || formMetaInfo?.endDate == null) {
		return true;
	}
	const datesValid = new Date(formMetaInfo?.startDate) < new Date(formMetaInfo?.endDate);
	return datesValid;
};

export const checkDateRangeValidity = (formMetaInfo: FormMetaInfo): boolean => {
	const startDate = document.querySelectorAll('[data-testid=datepicker-startDate]');
	const endDate = document.querySelectorAll('[data-testid=datepicker-endDate]');

	if (startDate[0] && endDate[0]) {
		const minAppStartDate = startDate[0].getAttribute('mindate');
		const maxAppStartDate = startDate[0].getAttribute('maxdate');

		if (!minAppStartDate || !maxAppStartDate) return false;

		const startValid =
			!formMetaInfo?.startDate ||
			(toISOString(minAppStartDate) <= toISOString(formMetaInfo.startDate) &&
				toISOString(formMetaInfo.startDate) <= toISOString(maxAppStartDate));

		const minAppEndDate = endDate[0].getAttribute('mindate');
		const maxAppEndDate = endDate[0].getAttribute('maxdate');

		if (!minAppEndDate || !maxAppEndDate) return false;

		const endValid =
			!formMetaInfo?.endDate ||
			(toISOString(minAppEndDate) <= toISOString(formMetaInfo.endDate) &&
				toISOString(formMetaInfo.endDate) <= toISOString(maxAppEndDate));

		return startValid && endValid;
	} else {
		return true;
	}
};
